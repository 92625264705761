import axios from 'axios'
import {boundMethod} from 'autobind-decorator'
import {getLoggers} from 'sopix/log'
import {apiEndPointOnError, apiEndPointOnFinalize, apiEndPointOnStart} from 'sopix/db/endPointMiddleware'

let DEFAULT_TIMEOUT = 10000
// Si es localhost dar timeout de 20 minutos
if (window.location.hostname === 'localhost'){
    DEFAULT_TIMEOUT = 1200000
}

const {log, debug} = getLoggers('ApiEndPoint')

export class CancelError extends Error{
    
}

const CANCEL_MESSAGE = "Operación cancelada por una operación previa"

let idCnt = 0

export class ApiEndPoint {
    
    cancelTokens = new Map()
    _error_label
    
    constructor(url, {timeout = DEFAULT_TIMEOUT, error_label} = {}) {
        this.url = url
        this.timeout = timeout
        this._error_label = error_label
    }

    cancelQueries = () => {
        for (let [_, cancelToken] of this.cancelTokens){
            cancelToken.cancel(CANCEL_MESSAGE)
            debug(()=>`CANCELADO`)
        }
        this.cancelTokens.clear()
    }

    @boundMethod
    async query(query){
        this.cancelQueries()

        const id = ++idCnt
        const middlewareId = `endpoint${id}`
        
        //Save the cancel token for the topModal request
        this.cancelTokens.set(id, axios.CancelToken.source())
        apiEndPointOnStart.notify(middlewareId)
        try {
            log(() => `${query}`)
            const results = await axios.post(
                this.url,
                {query: query},
                {
                    cancelToken: this.cancelTokens.token,
                    timeout: this.timeout,
                    withCredentials: true,
                }
            )

            return results.data
        }catch(error){

            if (axios.isCancel(error)) {
                throw new CancelError(error.message)
            } else {
                if (this._error_label){
                    error.message = `${this._error_label}: ${error.message}`
                }
                throw error
            }
        } finally {
            apiEndPointOnFinalize.notify(middlewareId)
            
            //Por alguna razón llegó hasta aquí a pesar de ser cancelada
            if (!this.cancelTokens.get(id)) {
                throw new CancelError(CANCEL_MESSAGE)
            }
            this.cancelTokens.delete(id)
        }
    }
}