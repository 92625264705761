import React, {forwardRef} from 'react'
import {Piece} from 'sopix/piece/piece'
import {observer} from 'mobx-react'
import {getPieceContainer} from 'sopix/piece/PieceFrame'
import {SubsetDbSource} from 'sopix/data-source/SubsetDbSource'
import {TableGraphql} from 'sopix/db-access/tableGraphql'
import {calcGraphqlOrder} from 'sopix/db/graphql-utils'
import {OrderDirection} from 'sopix/data/orderEntry'
import {EjecucionCuenta} from 'sop/db/EjecucionCuenta'
import {EjecucionCuentaFld, EjecucionCuentaTable} from 'sop/db/EjecucionCuentaTable'
import {Bar} from 'sopix/formComps/Bar'
import {Push} from 'sopix/formComps/Push'
import {HintIconButton, ICON_BUTTON_SIZE} from 'sopix/formComps/HintIconButton'
import {Direction} from 'sopix/formComps/comp-classes'
import {sopIcons} from 'sop/sop-icons'
import {makeStyles} from '@material-ui/core/styles'
import {sopColors} from 'sop/sop-colors'
import {TablePainter} from 'sopix/piece-objects/tablePainter'
import {EjecucionCuentaCol} from 'sop/db/EjecucionCuentaColumns'
import {Frame} from 'sopix/formComps/Frame'
import {BlTable} from 'sopix/boundless-table/BlTable'
import {CuentaCol} from 'sop/db/CuentaColumns'
import {CuentaFld} from 'sop/db/CuentaTable'
import {Cuenta} from 'sop/db/Cuenta'
import {RowFetcher} from 'sopix/db-access/rowFetcher'
import {EjecucionFld, EjecucionTable} from 'sop/db/EjecucionTable'
import {Ejecucion} from 'sop/db/Ejecucion'
import {observable, runInAction} from 'mobx'
import {getFieldExtractor} from 'sopix/data/data-utils'
import {HintIcon} from 'sopix/formComps/HintIcon'
import Typography from '@material-ui/core/Typography'
import {TareaFld} from 'sop/db/TareaTable'
import {SistemaFld} from 'sop/db/SistemaTable'
import {ProcedenciaFld} from 'sop/db/ProcedenciaTable'
import {ColumnsSummary} from 'sopix/db-components/ColumnsSummary'
import {TareaCol} from 'sop/db/TareaColumns'
import {SistemaCol} from 'sop/db/SistemaColumns'
import {ProcedenciaCol} from 'sop/db/ProcedenciaColumns'
import {EjecucionCol} from 'sop/db/EjecucionColumns'
import {Section} from 'sopix/formComps/Section'
import {BlRow} from 'sopix/boundless-table/BlRow'
import {icons} from 'sopix/icon/icons'
import {FormPoper} from 'sopix/piece-objects/formPoper'
import {EjecucionForm, EjecucionFormPiece} from 'sop/componentes/ejecucion/EjecucionForm'
import {PoperFrame} from 'sopix/modal-state/PoperFrame'
import {boundMethod} from 'autobind-decorator'
import {RowDeleter} from 'sopix/db-access/rowDeleter'
import {askAlert} from 'sopix/alert/asyncAlert'
import {AlertDefinition} from 'sopix/alert/alertDefinition'
import {EjecucionCuentaForm, EjecucionCuentaFormPiece} from 'sop/componentes/ejecucion/EjecucionCuentaForm'
import {cimStyles} from 'sopix/theme/cimStyles'
import {CuentaTipo} from 'sop/db/CuentaTipo'
import {CuentaTipoFld} from 'sop/db/CuentaTipoTable'
import {CuentaTipoCol} from 'sop/db/CuentaTipoColumns'
import {userAuth} from 'sopix/session/userAuth'
import {Permiso} from 'sop/db/Permiso'
import {ALERT_CANCEL, ALERT_ELIMINAR} from 'sopix/alert/alert-defs'

const COL_BUTTONS = 'BUTTONS'


export class EjecucionPainter extends TablePainter{

    /**
     * @param {EjecucionListPiece} list
     */
    _paint({id, value, row, rowId, index, dbCol, formatter, fld, flds, global: list}) {

        if (id === COL_BUTTONS && flds(EjecucionCuenta.idRol) === 0) {
            return <HintIconButton Icon={icons.Delete} title="Eliminar cuenta" 
                                   onClick={() => list.__cuentaDeleteClicked(rowId)} 
            />
        }

        switch(id){
            case EjecucionCuenta.cuenta:
                return fld(Cuenta.cuenta)
            case CuentaTipo.tipo:
                return flds(EjecucionCuenta.cuenta, Cuenta.tipo, CuentaTipo.tipo)
            default:
                return super._paint(
                    {id, value, row, rowId, index, dbCol, formatter, fld, flds})
        }
    }
    
    _fixColumn(col, id, dbCol) {
        col.props['dense'] = false
        switch (id){
            case COL_BUTTONS:
                col.props['noMargin'] = true
                col.maxWidth = col.minWidth = ICON_BUTTON_SIZE
                break
            default:
                super._fixColumn(col, id, dbCol)
        }
    }

}

export class EjecucionListPiece extends Piece{
    
    idField = EjecucionCuenta.idEjecucionCuenta
    
    deleter = new RowDeleter(this.world, EjecucionTable, 'DeleteEjecucion')
    _cuentaDeleter = new RowDeleter(this.world, EjecucionTable, 'DeleteCuentaEjecucion')
    
    @observable.shallow
    ejecucionRow

    /**
     * @param {PieceWorld} world
     */
    constructor(world) {
        super(world)
        
        const FIELDS = [
            ...EjecucionCuentaTable.regularFields,
            [EjecucionCuentaFld.cuenta, [
                CuentaFld.cuenta, [CuentaFld.tipo, [CuentaTipoFld.tipo]]
            ]]
        ]
        
        const COLUMNS = [
            [EjecucionCuenta.cuenta, CuentaCol.cuenta],
            CuentaTipoCol.tipo,
            EjecucionCuentaCol.comentarios,
            COL_BUTTONS,
        ]
        
        this.dbSource = new SubsetDbSource(world,
            EjecucionCuenta.idObra,
            new TableGraphql(EjecucionCuentaTable),
            FIELDS, {
                defaultGraphqlParams: {sort: calcGraphqlOrder(EjecucionCuenta.idRol, OrderDirection.DESC)},
        })
        
        this.painter = new EjecucionPainter(world, COLUMNS)

        this.rowFetcher = new RowFetcher(EjecucionTable, [
            ...EjecucionTable.regularFields,
            [EjecucionFld.tarea, [TareaFld.ref]],
            [EjecucionFld.sistema, [SistemaFld.sistema]],
            [EjecucionFld.procedencia, [ProcedenciaFld.procedencia]],
        ])
        
        this.ejecucionForm = new EjecucionFormPiece(world.cloneWithOwnErrors())
        this.ejecucionPoper = new FormPoper(this.ejecucionForm)
        
        this.cuentaForm = new EjecucionCuentaFormPiece(world.cloneWithOwnErrors())
        this.cuentaPoper = new FormPoper(this.cuentaForm)
        
        this.ejecucionForm.onSave.subscribe(this.refresh)
        this.cuentaForm.onSave.subscribe(this.refresh)
        this.deleter.onDelete.subscribe(this.refresh)
    }
    
    @boundMethod
    async refresh(){
        await Promise.all([
            this.dbSource.refresh(),
            this.fetchRow(),
        ])
    }
    
    @boundMethod
    async fetchRow(){
        const row = await this.rowFetcher.fetch({[Ejecucion.idObra]: this.dbSource.parentId},
            {exceptionIfNotFound: false})
        runInAction(()=>{
            this.ejecucionRow = row
        })
    }
    
    @boundMethod
    async _load(id){
        await this.dbSource.loadSubset(id)
        await this.fetchRow()
    }
    load = this._state(Symbol('LOAD'), this._load)

    
    @boundMethod
    async eliminar_ejecucion(){
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Eliminar ejecución?`,
            `Se eliminará la ejecución.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))){
            await this.deleter.delete(this.ejecucionRow[Ejecucion.idEjecucion])
        }
    }
    __eliminar_ejecucion = this._asyncAction(this.eliminar_ejecucion)

    @boundMethod
    async cuentaClicked({data}){
        await this.cuentaPoper.edit(data[EjecucionCuenta.idEjecucionCuenta])        
    }
    __cuentaClicked = this._asyncAction(this.cuentaClicked)
    
    
    @boundMethod
    async cuentaDeleteClicked(id){
        if (ALERT_ELIMINAR === await askAlert(new AlertDefinition(
            `¿Eliminar cuenta?`,
            `Se desvinculará la cuenta de la ejecución.`, [
                [ALERT_ELIMINAR, "Eliminar"],
                [ALERT_CANCEL, 'Cancelar'],
        ],))) {
            await this._cuentaDeleter.delete(id)
            await this.refresh()
        }
    }
    __cuentaDeleteClicked = this._asyncAction(this.cuentaDeleteClicked)
    
}




const useStyles = makeStyles(theme => ({
    ejecucionList: {
    },
    ejecucionBien: {
        background: sopColors.greenDarkBackground,
    },
    ejecucionMal: {
        background: sopColors.redDarkBackground,
    },
    iconBien: {
        color: sopColors.green,
    },
    iconMal: {
        color: theme.palette.secondary.main,
    },
    rol1: {
        background: sopColors.greenBackground,
    },
    rol2: {
        background: sopColors.redBackground,
    },
    sinEjecutar: cimStyles.margin2,
}))


const Row = forwardRef(({data, ...props}, ref) => {
    const cls = useStyles()

    return <BlRow ref={ref} className={
        cls[`rol${data[EjecucionCuenta.idRol]}`]
    } data={data} {...props} />
})


export const EjecucionList = getPieceContainer('list', observer(
    /**
     * @param {EjecucionListPiece} list
     */
    ({list}) => {

        const cls = useStyles()
        
        const eje = getFieldExtractor(list.ejecucionRow)
        
        const ejecutado = !!list.ejecucionRow
        
        return (
            <>
                <Bar direction={Direction.COLUMN} >
                    <Push />
                    {!userAuth.isAllowed(Permiso.modificarEjecuciones) ? null :
                        (!ejecutado
                            ? <>
                                <HintIconButton
                                    className={cls.iconMal}
                                    Icon={sopIcons.Mal}
                                    color="secondary"
                                    title="Ejecutar: por la competencia"
                                    onClick={() => list.ejecucionPoper.__createWith({
                                        [Ejecucion.idObra]: list.dbSource.parentId,
                                        [Ejecucion.competencia]: 1,
                                    })}
                                />
                                <HintIconButton
                                    className={cls.iconBien}
                                    Icon={sopIcons.Bien}
                                    title="Ejecutar: por nosotros"
                                    onClick={() => list.ejecucionPoper.__createWith({
                                        [Ejecucion.idObra]: list.dbSource.parentId,
                                        [Ejecucion.competencia]: 0,
                                    })}
                                />
                            </>
                            :   <HintIconButton
                                    Icon={sopIcons.Cuenta}
                                    badge="+"
                                    badgeColor={sopColors.badgeAdd}
                                    title="Añadir cuenta"
                                    onClick={() => list.cuentaPoper.createWith({
                                        [Ejecucion.idObra]: list.dbSource.parentId,
                                        [Ejecucion.idEjecucion]: list.ejecucionRow[Ejecucion.idEjecucion],
                                    })}
                                />
                        )
                    }
                </Bar>
                <Frame>
                    {!list.ejecucionRow ? <Typography className={cls.sinEjecutar}>Sin ejecutar</Typography> : <>
                        <Frame row margin2 shrink center
                               className={eje(Ejecucion.competencia) ? cls.ejecucionMal : cls.ejecucionBien} >
                            <Section center>
                                {eje(Ejecucion.competencia)
                                    ? <HintIcon large className={cls.iconMal} Icon={sopIcons.Mal} 
                                                title="por la competencia"/>
                                    : <HintIcon large className={cls.iconBien} Icon={sopIcons.Bien} 
                                                title="por nosotros"/>
                                }
                                <Typography variant="caption">Ejecutada</Typography>
                            </Section>

                            <ColumnsSummary data={list.ejecucionRow} columns={[
                                [EjecucionFld.tarea, [TareaCol.ref, {hint: 'Cotización'}]],
                                [EjecucionFld.sistema, [SistemaCol.sistema, {hint: 'Sistema'}]],
                                [EjecucionFld.procedencia,
                                    [ProcedenciaCol.procedencia, {hint: 'Procedencia'}]],
                                [EjecucionCol.m2Totales, {value: v => `${v} m²`}],
                                [EjecucionCol.m2Efectivos, {value: v => `${v} m²`}],
                            ]}/>

                            <HintIconButton
                                Icon={icons.Delete}
                                title="Eliminar ejecución"
                                onClick={list.__eliminar_ejecucion}
                            />
                        </Frame>
                        <Frame>
                            <BlTable fitContainer dense
                                 Row={Row}
                                 global={list}
                                 verticalAlign="center"
                                 data={list.dbSource.data}
                                 keyColumnId={list.idField}
                                 columns={list.painter.boundlessColumns}
                                 renderCellContent={list.painter.__boundlessPainter}
                                 onClick={list.__cuentaClicked}
                            />
                        </Frame>
                    </>
                    }
                </Frame>
                <PoperFrame poper={list.ejecucionPoper}>
                    <EjecucionForm form={list.ejecucionForm} />
                </PoperFrame>
                <PoperFrame poper={list.cuentaPoper}>
                    <EjecucionCuentaForm form={list.cuentaForm} />
                </PoperFrame>
            </>
        )
}), {row: true})
